.message-search-case-dropdown {
  width: 100%;
}

.message-chat-button {
  color: #7bad7e;
  height: 100%;
  width: 100%;
}

.message-chat-list-wrapper {
  overflow-y: scroll;
}

.chatlist-item-wrapper {
  height: 75vh;
  overflow-y: scroll;
}

.bg-color {
  background-color: #e8ecef;
}

.bg-white-color {
  background-color: #ffffff;
}

.border-color {
  border: 2px solid #e8ecef;
}

.message-no-chat-selection {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.height-hundred {
  height: 100%;
}

.height-hundred > .ant-spin-container {
  height: 100%;
}

.message-single-chat > .rce-citem {
  background-color: #e8ecef;
}

.message-chat-list-box {
  width: 100%;
  height: 100%;
}

.message-chat-list-box.ant-btn-icon-only.ant-btn-lg > * {
  font-size: 24px;
}

.message-chat-list-draw,
.message-case-details-draw {
  position: absolute;
}

.message-chat-list-draw > .ant-drawer-content-wrapper {
  width: 280px;
}

.message-case-details-draw > .ant-drawer-content-wrapper {
  width: 624px !important;
}

@media screen and (max-width: 720px) {
  .message-case-details-draw > .ant-drawer-content-wrapper {
    width: 352px !important;
  }
}

@media screen and (max-width: 320px) {
  .message-case-details-draw > .ant-drawer-content-wrapper {
    width: 308px !important;
  }
}

.message-chat-list-draw > .ant-drawer-content-wrapper > .ant-drawer-content,
.message-case-details-draw > .ant-drawer-content-wrapper > .ant-drawer-content {
  background-color: #ffffff;
}

.message-chat-list-draw
  > .ant-drawer-content-wrapper
  > .ant-drawer-content
  > .ant-drawer-wrapper-body
  > .ant-drawer-body {
  padding: 56px 0;
}

.message-chat-list-draw
  > .ant-drawer-content-wrapper
  > .ant-drawer-content
  > .ant-drawer-wrapper-body
  > .ant-drawer-header-no-title
  > .ant-drawer-close {
  right: unset;
  left: 0;
  color: #49548d;
  font-weight: 700;
}

.message-case-details-draw
  > .ant-drawer-content-wrapper
  > .ant-drawer-content
  > .ant-drawer-wrapper-body
  > .ant-drawer-body {
  padding: unset;
}

.message-case-details-draw
  > .ant-drawer-content-wrapper
  > .ant-drawer-content
  > .ant-drawer-wrapper-body
  > .ant-drawer-header,
.message-case-details-draw
  > .ant-drawer-content-wrapper
  > .ant-drawer-content
  > .ant-drawer-wrapper-body
  > .ant-drawer-header
  > .ant-drawer-close {
  padding: 8px 16px;
}

.message-chat-list-draw > .ant-drawer-mask,
.message-case-details-draw > .ant-drawer-mask {
  background-color: unset;
}

.ant-collapse.case-details-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  font-size: 20px;
}

.message-wrapper {
  position: relative;
  overflow-x: hidden;
  margin: 0 50px;
  padding: 46px;
  height: 83vh;
}

.message-list-messaging {
  overflow-y: scroll;
}

.message-list-height-background {
  height: calc(75vh - 320px);
  background-color: #e8ecef;
}

.case-details-collapse-icon {
  font-size: 20px;
}

.case-details-go-to-button {
  padding: 4px 0 !important;
}

.case-details-draw-page-header {
  padding: 16px 0 0 0 !important;
}

.case-details-draw-page-header .ant-page-header-heading-title {
  width: 100%;
}
