.title-color {
  color: #7eae80 !important;
}

.mif-wrapper-row {
  height: 640px;
  overflow: auto;
}

.updates-col-wrapper {
  padding: 0 12px 0;
}

.case-comparison {
  height: 100%;
  width: 100%;
}

.spin-wrapper,
.spin-wrapper > .ant-spin-container {
  height: 100%;
  width: 100%;
}

.wrapper-row {
  padding: 12px;
}

.hr-separation {
  margin-bottom: 16px;
}
