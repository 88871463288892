.waiting-room {
  padding: 42px;
  background: white;
}

.waiting-room-case-list-part-heading {
  display: flex;
  justify-content: space-between;
}

.brand-logo {
    height: 25px
}

.rexmd-logo {
    height: 25px
}

.navamd-logo {
    height: 20px
}

.rexmd-logo-small {
    height: 20px
}

.navamd-logo-small {
    height: 15px
}

.waitingroom-start-time {
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: bold;
    color: #7BAD7E;
}

.anticon-audio,
.anticon-video-camera {
  font-size: 20px;
  color: #7bad7b;
}

.waitingroom-container {
    margin: 15px 0;
    padding: 46px;
    background: #fff;
}

.fill-cell {
    width: 100%;
    height: 100%;
}

.waitingroom-container-text {
    text-align: center;
    color: black;
}

.waitingroom-container-background {
    background-color: lightgray;
}

.waitingroom-schedule-list-card {
    min-width: 280px;
  width: fit-content;
  background-color: white;
  position: relative;
}

.waitingroom-meeting-link-container {
    display: flex;
    column-gap: 8px;
}

.waitingroom-meeting-link {
    color: #7bad7b !important;
    font-weight: bold;
    text-decoration: underline;
    margin-left: 10px;
}

.title-row-wrapper {
  display: flex;
  justify-content: space-between;
}

.scheduled-switch-wrapper {
  display: flex;
  gap: 1em;
  align-items: center;
  padding-right: 1.5em;
}

.ant-switch {
  background-color: grey !important;
}

.ant-switch-checked {
  background-color: #7bad7b !important;
}

.waiting-room-card-part {
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: max-content max-content max-content;
  row-gap: 20px;
  min-height: 120px;
  grid-column-gap: 20px;
}

.waitingroom-schedule-video-audio-casid-p {
  font-size: 12px;
}

.waiting-room-filter-seacrh {
  display: flex;
}

.waiting-room-filter-button-modal {
  display: flex;
  justify-content: flex-end;
}

.waitingroom-schedule-list-card-no-case-consult {
  background-color: #f0f2f5;
}

.waitingroom-schedule-list-card-no-case-consult .ant-card-body h3,
.waitingroom-schedule-list-card-no-case-consult .ant-card-body h1 {
  margin: 0px;
}

.waitingroom-schedule-video-audio {
  display: flex;
  justify-content: space-between;
}

.waitingroom-schedule-list-card-view-text {
  display: grid;
  grid-template-columns: 100px 10px 100px;
}

.waiting-room-no-of-patient {
  background: white;
  width: fit-content;
  padding: 30px;
  margin-top: 40px;
  border-radius: 5px;
  margin-bottom: 40px;
}

.waiting-room-no-of-patient-details {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}

.waiting-room-no-of-patient-details-card {
  padding: 20px;
  width: 280px;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
}

.waiting-room-no-of-patient-details-data {
  display: grid;
  grid-template-columns: 115px 10px 115px;
}

.waiting-room-no-of-patient-details-data-colon {
  margin-left: auto;
  margin-right: auto;
}

.waiting-room-no-of-patient-part {
  margin-top: 40px;
  display: grid;
  grid-template-columns: auto auto auto;
}

.waiting-room-no-of-patient-part h5 {
  margin-bottom: 20px;
}

.waiting-room-no-of-patient-list-data {
  margin-top: 40px;
}

.ant-table-thead > tr > th {
  font-weight: 600;
  color: #7bad7b;
}

.ant-table-tbody > tr.ant-table-row > td {
  cursor: pointer;
}

.waitingroom-schedule-list-card-no-case-consult .ant-card-body {
  width: 100%;
}

.waitingroom-schedule-list-card .ant-card-body {
  width: 100%;
  padding: 10px;
}

.wating-room-table .ant-table-column-sorters-with-tooltip {
  background-color: #f4f4f4;
}

.wating-room-table .ant-table-tbody .ant-table-row {
  background-color: #ffff;
}

@media screen and (max-width: 1100px) {
  .waitingroom-schedule-video-audio h3 {
    font-size: 16px;
  }
  .waitingroom-schedule-list-card .ant-card-body {
    padding: 10px;
  }
  .waitingroom-schedule-video-audio-casid-p {
    font-size: 11px;
  }
  .home-page-content {
    padding: 20px;
  }
  .waitingroom-schedule-video-audio p {
    font-size: 12px;
  }
}

@media screen and (max-width: 800px) {
  .waiting-room-card-part {
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: auto auto;
  }
  .waiting-room-case-list-part-heading {
    flex-direction: column;
  }
}

@media screen and (max-width: 450px) {
  .waiting-room-card-part {
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: auto;
  }
  .waiting-room-no-of-patient-part {
    margin-top: 30px;
    grid-template-columns: auto;
    grid-row-gap: 15px;
  }
  .waiting-room-no-of-patient-details-card {
    width: 90% !important;
  }
}
