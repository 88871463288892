.consulted-case{
    padding: 42px;
    background: white;
}

.consulted-case-no-of-patient-list-data {
    margin-top: 40px;
}
.consult-case-date-filter{
    width: 100% ;
}
