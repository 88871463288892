.article-wrapper {
  padding: 46px;
  background: #fff;
}

.article-list-filter-wrapper {
  padding-top: 15px;
  display: flex;
  gap: 60px;
  background: rgb(244, 244, 244);
  padding: 10px 20px;
}

.article-list-search-filter,
.article-list-category-filter {
  width: 30%;
}

.article-list-clear {
  margin-top: auto;
}

.article-list-clear-button {
  width: 80px;
}

.article-list-data {
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: 50px;
  grid-row-gap: 50px;
}

.article-list-data .ant-card-head-title {
  font-size: 24px;
  font-weight: 600;
  color: #7c7c7c;
}

.article-card-description {
  height: 150px;
  overflow: hidden;
}

.article-card-description p {
  font-size: 15px;
}

.article-card-view {
  text-align: end;
  font-size: 17px;
  font-weight: 600;
}

.article-list-data .ant-card-hoverable:hover {
  background: #7bad7b;
  color: white;
}

.article-list-data .ant-card-hoverable:hover .ant-card-head-title {
  color: white !important;
}

.article-list-pagination {
  margin-top: 50px;
  background-color: white;
  float: right;
  padding: 10px;
}

.no-data-icon-wrapper {
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.no-data {
  margin-right: 12px;
}

.article-view-button {
  margin-bottom: 30px;
}

.article-view-content-wrapper {
  background-color: #ffffff;
  width: 100%;
  max-width: 1200px;  
  padding: 24px 28px;
}

.article-view-category-wrapper {
  display: flex;
  gap: 100px;
}

.article-view-text {
  margin-top: 5px;
  font-weight: 600;
  color: #7eae80;
}

@media (max-width: 1200px) {
  .article-view-content-wrapper {
    max-width: 1200px;
  }
}
@media (max-width: 788px), (max-width: 922px) {
  .article-list-data {
    grid-template-columns: auto;
  }
  .article-list-search-filter,
  .article-list-category-filter {
    width: 50%;
  }
  .article-list-filter-wrapper {
    gap: 40px;
  }
  .article-view-content-wrapper {
    max-width: 922px;
  }
  .article-view-category-wrapper {
    flex-direction: column;
    gap: unset;
  }
}
@media (max-width: 720px) {
  .article-list-data {
    grid-template-columns: auto;
  }
  .article-list-data .ant-card-head-title {
    font-size: 18px;
  }
  .article-card-view {
    font-size: 16px;
  }
  .article-list-filter-wrapper {
    flex-direction: column;
    gap: unset;
  }
  .article-list-search-filter,
  .article-list-category-filter {
    width: 100%;
  }
  .article-list-clear {
    margin-top: 10px;
  }
  .article-view-content-wrapper {
    max-width: 720px;
  }
  .article-view-content-wrapper h3.ant-typography {
    font-size: 18px;
  }
  .article-view-category-wrapper {
    flex-direction: column;
    gap: unset;
  }
}
