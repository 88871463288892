@import "~@csstools/normalize.css";

body {
  font-family: "Montserrat", sans-serif !important;
  color: #afafaf !important;
}
#root {
  display: block;
  position: relative;
  overflow: hidden;
  height: 100vh;
}

.ant-btn-primary {
  background-color: #7bad7e !important;
  box-shadow: unset !important;
  border-radius: 2px !important;
}

.ant-tooltip-inner {
  color: #ffffff !important;
}

h1 {
  font-size: 36px;
  font-weight: 700 !important;
}

a {
  color: #7c7c7c !important;
}
.ant-menu-item-selected a {
  color: #7bad7e !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  /* color: #7c7c7c !important; */
}

.ant-form label {
  font-family: "Montserrat", sans-serif !important;
}
.App {
  height: inherit !important;
}

.link-to-reg {
  position: relative;
  display: block;
  width: 100%;
  margin-top: 30px;
  padding-top: 30px;
  text-align: center;
  border-top: 1px solid #c5c7d5;
}

.align-text-center {
  text-align: center;
}

.primary-font-color {
  color: #7bad7e !important;
}

.margin-top-20 {
  margin-top: 20px;
}

.font-size-20 {
  font-size: 20px !important;
}

div.ant-tabs-content.ant-tabs-content-animated.ant-tabs-top-content > div.ant-tabs-tabpane.ant-tabs-tabpane-active > div > div:nth-child(1) > div > span > i {
  background-color: #7bad7e;
}

@media screen and (max-width: 1200px) {
  h1 {
    font-size: 32px;
  }
}
