.room {
  position: relative;
  background-color: black;
  height: 100%;
}

.room-video-part {
  display: flex;
  height: 85%;
}

.room-video-button-part {
  display: flex;
  justify-content: center;
  height: 15%;
}
.room button {
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 100px;


}
.room > h3 {
  text-align: center;
  font-weight: 300;
  margin-bottom: 1em;
}

.participant {
  background: black;
  padding: 10px;
  display: inline-block;
}

video {
  display: block;
}









.remote-participant .participant {
  width: 100% !important;
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center !important;
}

.remote-participant .participant video {
  width: 95% !important;
  height: 100% !important;
  object-fit: cover !important;
  margin-left: auto;
}

.remote-participant{
  width: 80% !important;
  height: 100% !important;
  background-color: black;
}

.remote-participant  .participant  .participant-Audio-section-inner  .participant-Audio-section {
  height: 180px;
  width: 180px;
  object-fit: cover;
  background-color: #e8ecef;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.remote-participant .participant .participant-Audio-section-inner {
  width: 30%;
  height: 250px;
  background-color: grey;

  display: flex;
  justify-content: center;
  align-items: center;
}

.remote-participant .participant .participant-Audio-section h1 {
  text-align: center;
}









.ant-radio-button-wrapper{
  background: white !important;
}









.local-participant .participant video {
  height: 40%;
  width: 100%;
  object-fit: cover;
  margin-top: 60%;
}

.local-participant  .participant  .participant-Audio-section-inner  .participant-Audio-section {
  height: 150px;
  width: 150px;
  object-fit: cover;
  background-color: #e8ecef;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.local-participant .participant .participant-Audio-section-inner {
  width: 100%;
  height: 250px;
  background-color: grey;

  display: flex;
  justify-content: center;
  align-items: center;
}

.local-participant .participant .participant-Audio-section h1 {
  text-align: center;
}

.local-participant .participant {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.local-participant {
  width: 20%;
}

.room-leave-room {
  color: rgb(225, 23, 23);
  font-size: 25px;
  border: 1px solid white;
  width: 60px;
  border-radius: 50%;
  height: 60px;  
  display: flex;
  justify-content: center;
  align-items: center;
   margin-top: auto;
  margin-bottom: auto;
  margin-right: 100px;
   
}



.room-audio-open,
.room-audio-cut {
  color: ghostwhite;
  font-size: 25px;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 100px;
}

.room-case-details {
  color: #4781de;
  font-size: 25px;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
}

.room-view-icon{
  color: ghostwhite;
  font-size: 25px;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 100px;
}

.room-video-button-part span:hover{
  background: #49548D;
}



@media screen and (max-width: 800px) {
  .local-participant {
    width: 25%;
  }

  .remote-participant{
    width: 75%;
  }

  .local-participant .participant .participant-Audio-section-inner {
    height: 200px;
  }
}

@media screen and (max-width: 500px) {


  .room-video-part{
    position: relative;
  }
  .remote-participant{
    width: 100%;
  }

  .local-participant {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 40%;
  }

  .local-participant .participant video {
    margin-top: 0;
  }

  .room-view-icon{
    margin-left: 0px;
    font-size: 20px;
    height: 40px;
    width: 40px;
  }

  .room-leave-room {
    margin-right: 0px;
    font-size: 20px;
    height: 40px;
    width: 40px;
  }
  .room-video-button-part{
    justify-content: space-evenly;
  }
  .room-audio-open,
.room-audio-cut {
  font-size: 20px;
  height: 40px;
  width: 40px;
}
.local-participant .participant .participant-Audio-section-inner {
  height: 120px;
}

.local-participant .participant .participant-Audio-section-inner .participant-Audio-section {
  height: 100px;
  width: 100px;
}
}