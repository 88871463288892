.pharmacy .anticon-copy {
  font-size: 20px;
  color: #7bad7e;
}

.content-color {
  color: #7eae80 !important;
}

.row-wrapper {
  margin-bottom: 20px;
}

.row-wrapper .ant-form-item {
  margin-bottom: 0px !important;
}
