.loginselection-part {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
}

.login-selection-part-one {
  width: 25%;
  height: 100%;
  text-align: center;
  padding: 8px;
}

.login-selection-part-two {
  width: 75%;
  height: 100%;
  background-color: #f4f4f4;
}

.login-selection-part-card {
  position: absolute !important;
  left: 22%;
  width: 56%;
  top: 20%;
  box-shadow: 11px 11px 18px -9px rgba(0, 0, 0, 0.25);
}

.login-selection-part-card .ant-card-body {
  display: flex;
  height: 100%;
}

.login-selection-part-one-image {
  width: 52%;
}

.login-selection-part-one-sub-image {
  width: 40%;
}

.login-selection-part-one-text {
  font-size: 12px;
}

.login-selection-part-login-signup p {
  font-weight: 900;
  font-size: 32px;
  margin-bottom: 10px;
}

.login-selection-part-login-signup {
  display: flex;
}

.login-selection-part-login-signup h2 {
  font-weight: 700;
}

.logo-active-inactive-selection {
  width: 180px;
  height: 168px;
  margin-bottom: 15px;
}

.login-link-sign-up {
  margin-left: 20px;
  color: #afafaf;
}

.ant-btn-primary {
  border-color: unset !important ;
}

.login {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 100px 80px;
}

.lock-icon {
  height: 15.22px;
  margin-right: 10px;
  width: 11px;
}

/* css for reset */
.reset-message {
  color: #543b90;
  margin: 16px 0px;
}

.safe-connection {
  display: flex;
  width: fit-content;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
}

.rc-anchor-normal {
  width: 45% !important;
}
.verification-wrapper{
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.verification-image-logo{
  display: flex;
  justify-content: center;
  width: 50%;
  align-items: center;
  margin: auto;
}

.verification-row .ant-col {
  margin: 10px 0px;
}

@media screen and (min-width: 1500px) {
  .login-selection-part-card {
    height: 50%;
  }
}

@media screen and (max-width: 1100px) {
  .login-selection-part-card {
    left: 16%;
    width: 68%;
  }

  .logo-active-inactive-selection {
    width: 180px;
  }

  .login-selection-part-one-image {
    width: 80%;
  }

  .login-selection-part-one-text {
    font-size: 14px;
  }

  .login-selection-part-one-sub-image {
    width: 60%;
  }
}

@media screen and (max-width: 800px) {
  .loginselection-part {
    flex-direction: row;
  }

  .login-selection-part-card {
    left: 09%;
    right: 10%;
    width: 85%;
  }

  .login-selection-part-one-image {
    width: 84%;
  }

  .login-selection-part-one-sub-image {
    width: 68%;
  }
}

@media screen and (max-width: 500px) {
  .loginselection-part {
    flex-direction: column;
  }

  .login-selection-part-one-image {
    width: 84%;
  }

  .login-selection-part-one-sub-image {
    width: 72%;
  }

  .login-selection-part-one-text {
    font-size: 8px;
  }

  .login-selection-part-one {
    height: 120px;
  }

  .login-selection-part-card .ant-card-body {
    flex-direction: column;
  }

  .login-selection-part-card {
    top: 10%;
    width: 84%;
  }

  .login-selection-part-two {
    width: 100%;
    height: 120vh;
  }

  .loginselection-part {
    overflow: auto;
  }

  .logo-active-inactive-selection {
    width: 132px;
    height: 120px;
  }

  .login-selection-part-login-signup p {
    margin-top: 40px;
    font-size: 25px;
  }
}

.ant-form-item {
  margin-bottom: 15px !important;
}

@media screen and (max-width: 425px) {
  .forgot-caption {
    font-size: 14px;
  }

  .lock-icon-text {
    font-size: 14px;
    color: #171c2f !important;
  }

  .login-button {
    margin-top: 8px !important;
    padding: 12px 46px 12px !important;
  }
}

@media screen and (max-width: 400px) {
  .login-selection-part-card {
    top: 9%;
    left: 3%;
    width: 94%;
  }
}

.login-section-row-wrapper {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin: unset !important;
}

.wrapper-text-align {
  text-align: center;
}

.user-button-link.ant-btn-link {
  color: #7bad7e;
}

.user-button-link.ant-btn-link:hover,
.user-button-link.ant-btn-link:focus {
  color: #000000;
}
