.outer-layout {
  min-height: 100vh;
}

.inner-content {
  margin: 0 50px;
  padding: 46px;
  height: calc(100vh - 73px);
  overflow-y: auto;
  transition: all 0.3s ease;
}

.main-drawer > .ant-drawer-content-wrapper > .ant-drawer-content {
  background-color: #ffffff;
}
.main-drawer
  > .ant-drawer-content-wrapper
  > .ant-drawer-content
  > .ant-drawer-wrapper-body
  > .ant-drawer-body {
  padding: 56px 0;
}
.main-drawer
  > .ant-drawer-content-wrapper
  > .ant-drawer-content
  > .ant-drawer-wrapper-body
  > .ant-drawer-header-no-title
  > .ant-drawer-close {
  right: unset;
  left: 0;
  color: #49548d;
  font-weight: 700;
}
.main-drawer > .ant-drawer-mask {
  background-color: unset;
}

.main-sider {
  background-color: #ffffff;
  border-right: 1px solid #cfd4d8;
  max-width: fit-content !important;
  min-width: fit-content !important;
  width: fit-content !important;
  overflow-y: auto;
  scrollbar-width: thin;
  overflow-x: hidden;
}

.main-sider.ant-layout-sider-collapsed {
  flex: 0 0 0px !important;
  max-width: 0px !important;
  min-width: 0px !important;
  width: 0px !important;
}

.main-sider > .ant-layout-sider-trigger {
  background-color: rgb(225, 230, 237);
}

.menu-item {
  height: 46px !important;
  line-height: 46px !important;
}

.site-layout-background {
  min-width: 220px !important;
  background-color: rgb(255, 255, 255) !important;
}

.logo {
  width: fit-content;
  height: fit-content;
}

.header-physician-dashboard {
  z-index: 4;
  width: 100%;
  height: 70px;
  box-shadow: 0 1px 0 0 #dddfeb;
}

.ant-layout-header {
  display: flex;
  justify-content: space-between;
  background-color: white;
}

.home-page {
  position: relative;
}

.home-page-header-logo {
  display: flex;
  flex-wrap: wrap;
}
.site-layout-background-part {
  width: 236px;
  z-index: 3;
}

.home-page-header-icon {
  margin: auto 40px auto 0px;
}

.homepage-section-two {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 80px);
}
.home-page-content-part-three {
  position: absolute;
  top: 0;
  left: 0;
  overflow: auto;
  width: 100%;
  height: 100%;
  padding-left: 236px;
  background: #e9eef2;
}

.header-sub-menu-item-user-board {
  height: 100%;
}

.home-page-content {
  padding: 30px;
  min-height: unset !important;
}

.home-page-content-main-part {
  margin: 24px 80px;
}

.home-page-second-side-bar {
  display: none;
}

.ant-drawer-header-no-title .ant-drawer-close {
  left: 0;
}

.ant-drawer-body {
  padding: 0px;
}

.ant-layout-header {
  padding-left: 12px;
  padding-right: 12px;
}

.ant-layout-header {
  padding-left: 24px;
  padding-right: 24px;
  border-bottom: 1px solid #afafaf;
}

.ant-menu svg {
  font-size: 20px;
}

.ant-menu-item-selected .icon-div svg {
  fill: #7bad7e;
}

.icon-div svg {
  fill: #848c94;
}

.home-page-header-logo svg {
  margin-top: auto;
  margin-bottom: auto;
}

.sidebar-icons {
  font-size: 20px !important;
}

.header-message-count-icon {
  margin-right: 25px !important;
}

@media screen and (min-width: 1500px) {
  .home-page-content-main-part {
    margin: 40px auto 40px auto;
    width: 1500px;
  }
}

@media screen and (max-width: 1200px) {
  .home-page-content-main-part {
    margin: 35px 20px 80px 20px;
  }
}

@media screen and (max-width: 800px) {
  .home-page-content {
    margin-left: 0px;
  }
  .site-layout-background-part {
    display: none;
  }
  .home-page-content-part-three {
    padding-left: 0px;
  }

  .home-page-second-side-bar {
    display: block;
  }
}

@media screen and (max-width: 500px) {
  .header-message-count-icon {
    margin-right: 5px !important;
    margin-left: 8px !important;
  }
  .ant-layout-header {
    padding: 0px 8px;
  }

  .home-page-header-icon {
    margin: auto 10px auto 0px;
  }

  .header-message-icon {
    font-size: 18px;
  }

  .heisenberg-notification-icon {
    margin-right: unset !important;
  }
  .home-page-content-main-part {
    margin: 35px 10px 80px 10px;
  }
}
