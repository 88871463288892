.waiting-room-case-list {
  padding: 42px 20px;
  background: white;
  margin-top: 50px;
}
.waiting-room-case-list-card-wrapper {
  margin: 8px 0;
}

.waiting-room-case-list-card {
  background-color: #f4f4f4;
}

.waiting-room-case-list-card-button {
  margin: 4px 8px;
}
