.case-ticket {
  padding: 42px;
  background: white;
  margin: 0 50px;
}

.case-ticket .case-ticket-filter {
  width: 25%;
}

.case-ticket .case-ticket-filter-wrapper {
  display: flex;
  gap: 25px;
}

.case-ticket .case-ticket-clear {
  margin-top: 30px;
}

.case-ticket-clear-button {
  width: 80px;
  background-color: #f4f4f4;
}

.case-ticket .case-ticket-list-data {
  margin-top: 40px;
}

.ant-table-tbody > tr.ant-table-row > td {
  cursor: unset;
}
