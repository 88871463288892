.case-View-patient-contact {
  display: flex;
  background-color: white;
  padding: 40px;
  margin: 20px 0px 40px;
  justify-content: space-between;
}

.case-card-wrapper {
  margin: 20px 0px 40px;
}

.case-card-wrapper .ant-card-body {
  padding: 0;
}

.case-view-patient-avatar {
  width: 130px;
  height: 130px;
}

.case-view-patient-avatar .ant-avatar-lg {
  width: 100%;
  height: 100%;
}

.case-view-patient-avatar .ant-avatar-lg .ant-avatar-string {
  font-weight: 600;
  margin: 0;
  padding: 0;
  color: #fff;
  position: absolute;
  top: 35%;
  left: 50%;
  font-size: 36px;
}

.case-view-patient-name {
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0;
  margin: 0;
  margin-bottom: 12px;
  padding: 0;
}

.case-View-patient-contact-details {
  display: flex;
}

.case-view-patient-contact-detail-view {
  margin-left: 40px;
}
.case-view-icon-view {
  font-size: 18px;
  margin-bottom: 8px;
}

.anticon-left {
  font-size: 18px !important;
}

.case-view-patient-card-details .ant-card-head {
  background-color: #e8ecef;
  border-bottom: unset;
  padding: 0px;
  margin: 0px;
}
.case-view-patient-card-details .ant-card-body {
  min-height: 400px;
}

.case-view-patient-card-details .ant-card-head .ant-tabs-tab-active {
  background-color: white;
  color: unset;
}

.case-view-patient-card-details .ant-card-head .ant-tabs-tab {
  width: 150px;
  padding-left: 0 10px 0 10px !important;
  text-align: center;
}

.case-view-patient-card-details .ant-card-head .ant-tabs-ink-bar {
  background-color: unset;
}

.case-patient-details-data {
  display: flex;
  width: 30%;
  justify-content: space-between;
  margin-bottom: 15px;
}

.case-view-patient-card-details{
  position: relative;
}

.case-view-patient-message-icon{
  display: flex;
  gap: 5px;
  justify-content: right;
  align-items: center;
  position: absolute;
  top: 17px;
  width: 100%;
}

.case-view-patient-card-details .ant-card-body {
  padding-left: 0px;
  padding-right: 0px;
}
.case-detailed-view-part{
  padding: 0px 40px 40px 40px;
}
.case-view-patient-card-details-tabs .ant-tabs-top-bar{
  padding: 60px 40px 0px 40px;
}

.case-patient-details h2 {
  margin-bottom: 32px;
  font-weight: 700;
}

.case-patient-details-data span:nth-child(2) {
  text-align: left;
  width: 100px;
}

.case-view-icon-view {
  color: #49548d;
}

.case-details-case-status-wrapper {
  display: flex;
}

.flex-spacing {
  justify-content: space-between;
}

.patient-edit-input-style{
  width: 100%;
}
.case-details-case-status-wrapper > * {
  margin: 0 0 0 8px;
}
.patient-edit-button{
  margin-left: auto;
  margin-bottom: 5px;
  width:fit-content;
}

.case-details-disabled-textarea.ant-input-disabled {
  color: #7c7c7c;
  background-color: #ffffff;
}

.no-rx-button{
  color:red ;
  border: 2px solid red ;
}

.rx-button{
  color:#7bad7e;
  border: 2px solid #7bad7e;
}

.prescribe-medicine-table{
  text-transform: capitalize;
  margin: 30px 0px;
}
.heisenberg-error-text{
  color: red ;
  font-weight: bold;
}
.document-view {
  width: 100%;
}

.document-view > .ant-card-body {
  padding: unset;
}

.document-view-modal-image {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.align-text-right {
  text-align: right;
}

.document-view-image-icon {
  width: 188px;
  height: 188px;
  cursor: pointer;
}

.document-view-parts {
  width: 100%;
  margin-bottom: 30px;
}

.case-drawer-wrapper{
  padding-left: 30px;
}

.document-view-body {
  justify-content: space-evenly;
}

.document-view-parts-image {
  margin: 0 16px 16px 0;
}

.document-view-parts-pdf-icon {
  font-size: 188px;
  color: #7bad7b;
}
.additional-documents-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.additional-documents-item {
    padding: 5px;
    width: 188px;
    height: 188px;
    cursor: pointer;
}

.additional-documents-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.additional-documents-item {
    padding: 5px;
    width: 188px;
    height: 188px;
    cursor: pointer;
}

.prescribe-button-tag-line {
  margin: 8px 0;
}

.document-view-image-icon-noimage {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: auto !important;
  margin-top: auto !important;
}

.document-view-image-icon-pdf {
  margin-top: 25px;
}

.sub-content-color {
  color: #7eae80 !important;
}

.case-details-row-wrapper {
  margin-top: 50px;
}

.case-view-mif-answer-priority-status-red {
    color: red;
}

.case-view-mif-answer-priority-status-red {
    color: green;
}

.case-view-mif-image {
  width: 52%;
  height: auto;
}

.case-view-mif-icon-group {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.case-view-mif-icon {
    width: 60px;
    height: 60px;
}

.case-view-mif-icon-wrapper {
    margin: 5px;
}

.case-view-mif-icon-text {
    padding-left: 10px;
    color: #7eae80 !important;
}

.case-view-client-logo {
    height: 40px;
}

.case-form-actions-row{
  text-align: center;
  background: rgb(123, 173, 126,0.2);
  margin: 0px;
  padding: 30px;
}
.case-form-action-title{
  margin-bottom: 30px !important;
  font-weight: 900 !important;
}

.case-form-actions-row .ant-btn-lg{
  border: 2px solid #7bad7e ;    
    height: 45px !important;
    background: rgb(123, 173, 126,0.0) !important;
    color: #7bad7e;
    font-weight: 600;
    transition: initial;
}
.case-form-actions-row .ant-btn-lg:hover{
  background: rgb(123, 173, 126,0.3) !important;

}
@media (max-width: 800px) {
  .case-form-actions-row .ant-btn-lg{
    margin-top: 30px;
    font-weight: 500px;  
  }
  .case-form-actions-row{
    padding: 15px;

  }
  .case-form-action-title{
    margin-bottom: 10px !important;
    font-weight: 500 !important;
  }

}
