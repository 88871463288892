.reset-password-component-layout {
  min-height: 100vh;
  align-items: center;
  justify-content: center;
}

.reset-password-component-layout-content {
  flex: unset;
}

.reset-password-component-wrapper {
  width: 100%;
  max-width: 514px;
}

@media (max-width: 1200px) {
  .reset-password-component-wrapper {
    max-width: 720px;
  }
}
@media (max-width: 788px), (max-width: 922px) {
  .reset-password-component-wrapper {
    max-width: 580px;
  }
}
@media (max-width: 720px) {
  .reset-password-component-wrapper {
    max-width: 320px;
  }
}

.reset-password-component-title {
  font-size: 28px;
  font-weight: 700;
  text-align: center;
}

.reset-password-component-title-level {
  text-align: center;
}

.reset-password-component-alert {
  margin: 16px 0;
}

.reset-password-component-form-item {
  text-align: center;
}

.reset-password-component-button {
  margin-left: 8px;
}
