.physician-dashboard {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  grid-auto-rows: 1fr;
  gap: 20px;
}

.uppercase { text-transform: uppercase; }

.physcian-dashboard-part-one {
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
}

.physcian-dashboard-part-card-of-cases {
  box-shadow: 11px 11px 18px -12px rgba(0, 0, 0, 0.20);
}

.physcian-dashboard-part-card-of-cases .ant-card-body {
  padding: 30px;
  height: 100%;
}

.physcian-dashboard-part-card-of-cases .ant-card-body .ant-spin-nested-loading, .physcian-dashboard-part-card-of-cases .ant-card-body .ant-spin-nested-loading .ant-spin-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.physcian-dashborad-card-heading {
  margin: 20px 0 20px;
  max-width: 100%;
}

.physcian-dashborad-card-heading h2 {
  margin-bottom: 0px;
  font-size: 28px;
}

p.physcian-dashboard-card-heading-def {
  line-height: 1.5;
  font-size: 16px;
  margin: 0px 0 0px;
  padding-right: 15px;
}

.physcian-dashborad-card-button {
  width: 100%;
}

.physcian-dashborad-card-button .ant-btn {
  height: 50px;
  width: 100%;
}

.physcian-dashborad-card-heading h2 {
  line-height: 1.2;
}

.physcian-dashborad-card-heading h2:last-of-type {
  margin-bottom: 20px;
}


.physcian-dashborad-card-heading p {
  line-height: 1.5;
}

@media screen and (max-width:1200px) {
  .physcian-dashborad-card-heading {
    max-width: 100%;
  }
  p.physcian-dashboard-card-heading-def {
    font-size: 14px;
    padding: 0px;
  }
  .physcian-dashboard-part-card-of-cases .ant-card-body {
    padding: 30px;
  }
  .physcian-dashborad-card-heading h2 {
    margin-bottom: 0px;
    font-size: 24px;
}
}

@media screen and (max-width: 950px) {
  .physcian-dashborad-card-heading {
    max-width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .physcian-dashboard-part-one {
    flex-direction: column;
  }
  .physcian-dashborad-card-heading {
    min-height: 230px;
  }
  .physcian-dashboard-part-card-of-cases {
    width: 100%;
    margin-bottom: 30px;
  }
  .physcian-dashboard-part-one {
    padding: 0px;
  }
  .physcian-dashborad-card-button .ant-btn {
    width: 100%;
  }
  .physcian-dashborad-card-heading {
    max-width: 100%;
  }
  .physician-dashboard h1:first-child {
    font-size: 29px;
  }
  .physcian-dashborad-card-heading h2 {
    margin-bottom: 0px;
    font-size: 20px;
  }
  .home-page-content {
    padding: 5px;
  }
  .physcian-dashboard-part-card-of-cases .ant-card-body {
    padding: 20px;
  }
  .physician-dashboard {
    padding: 42px 50px;
  }
}

@media screen and (max-width:500px) {
  .physician-dashboard {
    padding: 0px;
  }
  .physcian-dashborad-card-heading {
    min-height: 260px;
  }
  .physcian-dashborad-card-button .ant-btn {
    font-size: 10px;
  }
  .physcian-dashboard-part-card-of-cases .ant-card-body {
    padding: 15px;
  }
  .physcian-dashborad-card-button {
    bottom: -20px;
  }
}