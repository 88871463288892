.image-photo {
  width: 168px;
}

.image-photo-id {
  width: 240px;
}

.height-auto {
  height: auto;
}

.image-view {
  width: 100%;
  margin-bottom: 24px;
}

.image-wrapper-col {
  margin: 0 16px 16px 0;
}

.no-image-text {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: auto !important;
  margin-top: auto !important;
}

.sub-title-text-color {
  color: #7eae80 !important;
}
