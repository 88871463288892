.message-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
}

.message-header-avatar {
  vertical-align: top;
}

.message-header-section-wrapper {
  display: inline-block;
  padding: 8px 8px;
}

.message-header-title-wrapper {
  min-height: 40px;
}

.message-header-title {
  color: #7bad7e;
  font-size: 18px;
  font-weight: 600;
  margin: 0 4px;
}

.message-header-subtitle {
  font-size: 14px;
  margin: 0 4px;
}

.message-header-option-icon {
  font-size: 16px;
}

.message-header-option-wrapper > .ant-menu,
.message-header-option-wrapper > .ant-menu > .ant-menu-submenu,
.message-header-option-wrapper
  > .ant-menu
  > .ant-menu-submenu
  > .ant-menu-submenu-title {
  height: 100%;
}

.message-header-option-wrapper
  > .ant-menu
  > .ant-menu-submenu
  > .ant-menu-submenu-title {
  display: inline-block;
}

.message-header-option-wrapper
  > .ant-menu
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .anticon {
  font-size: 28px;
  vertical-align: bottom;
}

.message-case-details-btn {
  margin-right: 10px;
}