.centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.gap {
    gap: 20px;
}

.col {
    flex-direction: column;
}

.patientIcon {
    width: 50px;
    height: 50px;
    background: #7BAD7E;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text {
    margin: 0;
}

.actionButtonBlock {
    width: 210px;
}

.cancelButton {
    border-radius: 6px;
    padding: 12px;
    background-color: #e1e1e1;
}

.cancelButton:hover {
    color: white;
    border-color: white;
    background-color: #bcbcbc;
}

.startCallButton {
    color: white;
    border-radius: 6px;
    padding: 12px;
    background-color: #7BAD7E;
}

.startCallButton:hover {
    color: white;
    border-color: white;
    background-color: #5F8C6C;
}

.endCallButton {
    color: white;
    border-radius: 6px;
    padding: 12px;
    background-color: rgb(175, 35, 35);
}

.endCallButton:hover {
    color: white;
    border-color: white;
    background-color: rgb(121, 24, 24);
}
