
.myaccount-physician{
  background-color: #fff;  
  padding: 40px;
  margin-top: 50px;
}

.myaccount-profile-pic-inner-section-image{
  width: 150px;
  height: 150px;
  border-radius: 50%;

}


.myaccount-personal-information-heading{
  margin:20px auto 20px 2.5%;
  width: 100%;
}

.myaccount-profile-pic .ant-upload-select-picture-card {
width: 150px;
height: 150px;
margin:20px auto 20px 2.5%;
border-radius: 50%;

}
.myaccount-form-flex-name{
  display:flex; 
  justify-content: space-between; 
}
.myaccount-form-flex-name .ant-form-item{
  width:35%

}
.myaccount-form-flex-name .register-form-credential{
  width:15%;
}

.myaccount-profile-pic-inner-section{
  position: relative;
}

.myaccount-profile-pic-edit{
  position: absolute;
    right: 10px;
    bottom: 0;
    color: #fff;
    background-color: #7bad7b ;
    padding: 10%;
    border-radius: 25%;
}

.myaccount-profile-pic .ant-upload-select-picture-card > .ant-upload {
padding: 5px;
}

.ant-radio-group-large .ant-radio-button-wrapper {
  margin-left: 40px;
}
.ant-select-selection-item-remove .anticon{
  font-size: 12px !important;
}

.myaccount-save-button{
  margin-left: 70%;
  width: 30%;
  height: 50px;
}


@media screen and (max-width: 1024px) {
  .ant-radio-group-large .ant-radio-button-wrapper {
    margin-left: 20px;
  }

}

@media screen and (max-width:800px){
  .myaccount-physician {
    padding: 25px;    
  }
  .myaccount-save-button{
    margin-left: 50%;
    width: 50%;
  }
}


@media screen and (max-width:550px){
  .myaccount-profile-pic .ant-upload-select-picture-card {
    width: 120px;
    height: 120px;
    margin: 20px auto 20px 2.5%;
  }
  .myaccount-save-button{
    margin-left: 30%;
    width: 70%;
  }
  .myaccount-physician {
    padding: 25px;
    margin: 20px;
  }
  .physician-account-width-form{
    width: 100% !important;

  }
  .myaccount-form .ant-form-item{
    margin-bottom: 15px;
  }
  .ant-radio-group-large .ant-radio-button-wrapper {
    margin-left: 7px;
    font-size: 12px;
  }

  .myaccount-form-flex-name{
   flex-direction: column;
  }
  .myaccount-form-flex-name .ant-form-item,
  .myaccount-form-flex-name .register-form-credential{
    width:100%;
  }
}