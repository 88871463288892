.password-change-physician {
  background-color: #fff;
  padding: 40px;
  margin-top: 50px;
}

.password-change-save-button {
  margin-left: 70%;
  width: 30%;
  height: 50px;
}

@media screen and (max-width: 800px) {
  .password-change-physician {
    padding: 25px;
  }
  .password-change-save-button {
    margin-left: 50%;
    width: 50%;
  }
}

@media screen and (max-width: 550px) {
  .password-change-save-button {
    margin-left: 30%;
    width: 70%;
  }
  .password-change-physician {
    padding: 25px;
    margin: 20px;
  }
}
